import { ClientMetaFunction, mergeMeta } from '@app/utils/remix/play-now-meta';
import { loadShows } from '@play-now/core/api/load-shows';
import { getRemixMeta } from '@play-now/video/components/Meta/PlayMetaData';
import { toTVSeriesListSchema } from '@play-now/video/components/PageMetaData/JsonLd';
import { useInitialData } from '@play-now/video/config/VideoAppContext';
import { ShowOverviewPage } from '@play-now/video/pages/ShowOverview/ShowOverviewPage';
import { json, LoaderFunctionArgs } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';

import { PlayLayout } from './PlayLayout';
import { setupPlayUtils } from './remix-route-helper';

export const loader = async (args: LoaderFunctionArgs) => {
  const { params, axios, config, i18n } = setupPlayUtils(args);
  const shows = await loadShows(axios, params.onlyActiveShows !== 'false');

  const metaData = getRemixMeta({
    config,
    title: i18n.t('showOverview.pageTitle'),
    description: i18n.t('showOverview.metaDescription'),
    'script:ld+json': toTVSeriesListSchema(shows, i18n, config.baseUrl, config.modernImagesBaseUrl),
  });

  return json({ shows, meta: metaData });
};

export const meta: ClientMetaFunction<typeof loader> = ({ data, matches }) => mergeMeta({ data, matches });

// Exporting an empty client loader enables instant client-side navigation.
export const clientLoader = async () => ({ shows: undefined });

const ShowOverviewRoute = () => {
  const { shows } = useLoaderData<typeof loader>();
  const initialData = useInitialData();
  return (
    <PlayLayout pageId='showOverview'>
      <ShowOverviewPage initialData={{ ...initialData, shows }} />
    </PlayLayout>
  );
};

export default ShowOverviewRoute;
